.bg-primary,
.btn-primary,
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-soft-primary:hover,
.btn-soft-primary:focus,
.btn-soft-primary:active,
.btn-soft-primary.active,
.btn-soft-primary.focus,
.badge-primary,
.pagination .page-item.active .page-link,
.nav-pills .nav-link.active,
.custom-control-input:checked ~ .custom-control-label:before,
#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2,
.social-icon li a:hover,
#topnav .navbar-toggle.open span:hover,
.gradient,
.flex-control-paging li a.flex-active,
.owl-theme .owl-dots .owl-dot span,
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots.clickable .owl-dot:hover span,
.watch-video a .play-icon-circle,
.sidebar .widget .tagcloud > a:hover,
.flatpickr-day.selected,
.flatpickr-day.selected:hover,
.bg-animation-left.crypto-home:after,
.classic-app-image .bg-app-shape:after,
.classic-saas-image .bg-saas-shape:after,
.work-container.work-modern .icons .work-icon:hover,
.features.fea-primary:hover {
	background-color: #2f55d4 !important;
}

.text-primary,
.bg-soft-primary,
.btn-soft-primary,
.btn-outline-primary,
.badge-outline-primary,
.alert-outline-primary,
.breadcrumb .breadcrumb-item.active,
.breadcrumb .breadcrumb-item a:hover,
.faq-content .card a.faq[data-toggle="collapse"],
.faq-content .card a.faq[data-toggle="collapse"]:before,
#topnav .has-submenu.active .submenu li.active > a,
#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a,
#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a:active,
#topnav .navigation-menu > li .submenu li a:hover,
#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a,
#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li .submenu li a:hover,
#topnav .navigation-menu > li.has-submenu.open > a,
#topnav .has-submenu.active a,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a,
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a,
.course-feature .title:hover,
.pricing-rates.business-rate:hover .title,
.blog .content .title:hover,
.blog .content .post-meta .readmore:hover,
.sidebar .widget .blog-catagories li a:hover,
.sidebar .widget .post-recent .post-recent-content a:hover,
.media-list .media .media-heading:hover,
.job-box .position a:hover,
.job-box .company-name:hover,
.event-schedule .content .title:hover,
.courses-desc .content .title:hover,
.container-filter li a.active,
.container-filter li a:hover,
.team .name:hover,
.catagories a:hover,
.explore-feature:hover .icon,
.explore-feature:hover .title,
.work-container.work-classic .content .title:hover,
.work-container.work-grid .content .title:hover,
.work-container.work-modern .content .title:hover,
.shop-list .content .product-name:hover,
.features.fea-primary .content .title:hover {
	color: #2f55d4 !important;
}

.btn-primary,
.btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.badge-outline-primary,
.btn-soft-primary:hover,
.btn-soft-primary:focus,
.btn-soft-primary:active,
.btn-soft-primary.active,
.btn-soft-primary.focus,
.alert-primary,
.alert-outline-primary,
.pagination .page-item.active .page-link,
.form-group .form-control:focus,
.form-group .form-control.active,
.custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:focus ~ .custom-control-label::before,
.form-control:focus,
.social-icon li a:hover,
#topnav .has-submenu.active.active .menu-arrow,
#topnav.scroll .navigation-menu > li:hover > .menu-arrow,
#topnav.scroll .navigation-menu > li.active > .menu-arrow,
#topnav .navigation-menu > li:hover > .menu-arrow,
#topnav .has-submenu .submenu .has-submenu:hover .submenu-arrow,
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow,
.pricing-rates.business-rate:hover,
.pricing-rates.starter-plan,
.event-schedule:hover,
.container-filter li a.active,
.container-filter li a:hover,
.flatpickr-day.selected,
.flatpickr-day.selected:hover {
	border-color: #2f55d4 !important;
}

.btn-primary,
.btn-soft-primary,
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active {
	box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus {
	background-color: #2443ac !important;
	border-color: #2443ac !important;
}

.btn-soft-primary {
	background-color: rgba(47, 85, 212, 0.2) !important;
	border: 1px solid rgba(47, 85, 212, 0.2) !important;
}

.alert-primary,
.pagination .page-item .page-link:hover,
::selection {
	background-color: rgba(47, 85, 212, 0.9);
}

.alert-primary .alert-link {
	color: #122258;
}

.pagination .page-item .page-link:hover {
	border-color: rgba(47, 85, 212, 0.9);
}

a.text-primary:hover,
a.text-primary:focus {
	color: #284bc1 !important;
}

.social-icon li a:hover .fea-social {
	fill: #2f55d4;
}

.bg-overlay-gradient {
	background-color: rgba(47, 85, 212, 0.6);
}

.bg-overlay-gradient2 {
	background-image: linear-gradient(90deg, #2eca8b, #2f55d4, #2eca8b);
}

.gradient {
	background: linear-gradient(45deg, #2f55d4, #2f55d4) !important;
}

.features .image:before {
	background: linear-gradient(45deg, transparent, rgba(47, 85, 212, 0.09));
}

.features .icon,
.features .icons,
.app-feature-shape-right:after,
.app-feature-shape-left:after,
.saas-feature-shape-left:after,
.saas-feature-shape-right:after,
.social-feature-left:after,
.social-feature-right:after {
	background: rgba(47, 85, 212, 0.1);
}

.features:hover .image:before {
	background: rgba(47, 85, 212, 0.07);
}

.key-feature .icon {
	background: linear-gradient(45deg, transparent, rgba(47, 85, 212, 0.15));
}

.owl-theme .owl-dots .owl-dot span {
	background: rgba(47, 85, 212, 0.5) !important;
}

.play-icon i,
.play-icon i:hover {
	-webkit-text-stroke: 2px #2f55d4;
}

.play-icon i:hover {
	-webkit-text-fill-color: #2f55d4;
}

.event-schedule .date .day {
	background: rgba(47, 85, 212, 0.1);
	box-shadow: 0px 0px 2px 0.25px rgba(47, 85, 212, 0.5);
}

.classic-app-image .bg-app-shape:after,
.classic-saas-image .bg-saas-shape:after {
	box-shadow: 0 0 40px rgba(47, 85, 212, 0.5);
}

.app-feature-shape-right:after,
.app-feature-shape-left:after,
.saas-feature-shape-left:after,
.saas-feature-shape-right:after,
.social-feature-left:after,
.social-feature-right:after {
	box-shadow: 0 0 40px rgba(47, 85, 212, 0.1);
}

.bg-circle-gradiant {
	background: radial-gradient(
		circle at 50% 50%,
		rgba(47, 85, 212, 0.1) 0%,
		rgba(47, 85, 212, 0.1) 33.333%,
		rgba(47, 85, 212, 0.3) 33.333%,
		rgba(47, 85, 212, 0.3) 66.666%,
		rgba(47, 85, 212, 0.5) 66.666%,
		rgba(47, 85, 212, 0.5) 99.999%
	);
}

.bg-soft-primary {
	background-color: rgba(47, 85, 212, 0.2) !important;
	border: 1px solid rgba(47, 85, 212, 0.2) !important;
}

.btn-video {
	background-color: #5ba2ff !important;
	border-color: #5ba2ff !important;
}
.btn-video:hover,
.btn-video:focus,
.btn-video:active,
.btn-video.active,
.btn-video.focus {
	background-color: #1960b2 !important;
}

.btn-kakao {
	background-color: #fddc3f !important;
	border: 1px solid #fddc3f !important;
	color: #3b2929 !important;
	border-radius: 3px !important;
	/* -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3); */
	font-size: 14px;
	font-weight: 500;
	/* box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3); */
}

.btn-kakao:hover,
.btn-kakao:focus,
.btn-kakao:active,
.btn-kakao.active,
.btn-kakao.focus {
	background-color: #f1d12e !important;
	border-color: #f1d12e !important;
	color: #3b2929 !important;
}

.btn-soft-kakao,
.btn-outline-kakao:hover,
.btn-outline-kakao:focus,
.btn-outline-kakao:active,
.btn-outline-kakao.active,
.btn-outline-kakao.focus,
.btn-outline-kakao:not(:disabled):not(.disabled):active {
	box-shadow: 0 3px 5px 0 rgba(254, 229, 0, 0.3);
}

.float {
	position: fixed;
	bottom: 25px;
	right: 15px;
	z-index: 1;
}

.float-80 {
	position: fixed;
	bottom: 80px;
	right: 20px;
}

[type="file"] {
	height: 100%;
	overflow: hidden;
	width: 100%;
}

.begin-background {
	background-color: white;
}

th {
	background-color: #fafafa;
	color: #666666;
}

@media (min-width: 769px) {
	.popup {
		position: absolute;
		z-index: 99999999;
		width: "500px";

		-webkit-box-shadow: 0px 3px 5px 0 rgba(248, 249, 252, 0.3);
		box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
	}

	.about-image {
		width: 45%;
	}

	.about-logo {
		width: 30%;
		margin-bottom: 15px;
	}

	.about-header {
		height: 530px;
	}

	.about-padding {
		padding-left: 16%;
		padding-right: 16%;
	}

	.about-fontsize {
		font-size: 42px;
	}

	.about-title {
		font-size: 30px;
		font-weight: 400;
		word-break: keep-all;
	}

	.about-desc {
		font-size: 16px;
		font-weight: 400;
		word-break: keep-all;
		line-height: 1.6;
	}

	.fee-header {
		height: 430px;
	}

	.fee-compare {
		width: 30%;
	}

	.fee-save {
		width: 50%;
	}

	.main-title {
		font-size: 42px;
	}

	.subtitle-begin {
		color: #333333;
		font-size: 32px;
		font-weight: 400;
	}

	.subtitle-index {
		color: #333333;
		font-weight: 600;
	}

	.subtitle-desc {
		color: #333333;
		font-size: 18px;
		font-weight: 400;
	}

	.diagnosis-header {
		height: 350px;
	}

	.diagnosis-btn-width {
		width: 250px;
		margin-top: 30px;
	}

	.diagnosis-arrow {
		display: block;
	}

	.diagnosis-btn-kakao {
		background-color: #fddc3f !important;
		border: 1px solid #fddc3f !important;
		color: #3b2929 !important;
		font-size: 16px;
		padding: 2% 8%;
		width: 150px;
		height: 55px;
		border-radius: 10px;
		-webkit-box-shadow: 0px 3px 5px 0 rgba(248, 249, 252, 0.3);
		box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
	}

	.diagnosis-btn-kakao:hover,
	.diagnosis-btn-kakao:focus,
	.diagnosis-btn-kakao:active,
	.diagnosis-btn-kakao.active,
	.diagnosis-btn-kakao.focus {
		background-color: #f1d12e !important;
		border-color: #f1d12e !important;
		color: #3b2929 !important;
	}
}

@media (max-width: 768px) {
	.popup {
		position: absolute;
		z-index: 99999999;
		width: "350px";
		-webkit-box-shadow: 0px 3px 5px 0 rgba(248, 249, 252, 0.3);
		box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
	}

	.about-image {
		width: 90%;
	}

	.about-logo {
		width: 60%;
		margin-bottom: 15px;
	}

	.about-header {
		height: 330px;
	}

	.about-padding {
		padding-left: 10%;
		padding-right: 10%;
	}

	.about-fontsize {
		font-size: 24px;
	}

	.about-title {
		font-size: 24px;
		font-weight: 400;
		word-break: keep-all;
	}

	.about-desc {
		font-size: 14px;
		font-weight: 400;
		word-break: keep-all;
		line-height: 1.6;
	}

	.fee-header {
		height: 330px;
	}

	.fee-compare {
		width: 60%;
	}

	.fee-save {
		width: 60%;
	}

	.main-title {
		font-size: 30px;
	}

	.subtitle-begin {
		color: #333333;
		font-size: 26px;
		font-weight: 400;
	}

	.subtitle-index {
		color: #333333;
		font-size: 20px;
		font-weight: 600;
	}

	.subtitle-desc {
		color: #333333;
		font-size: 18px;
		font-weight: 400;
	}

	.diagnosis-header {
		height: 200px;
	}

	.diagnosis-btn-width {
		width: 100%;
		height: 60px;
		margin-top: 20px;
	}

	.diagnosis-arrow {
		display: none !important;
	}

	.diagnosis-btn-kakao {
		background-color: #fddc3f !important;
		border: 1px solid #fddc3f !important;
		color: #3b2929 !important;
		font-size: 16px;

		width: 150px;
		height: 55px;
		border-radius: 10px;
		-webkit-box-shadow: 0px 3px 5px 0 rgba(248, 249, 252, 0.3);
		box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
	}

	.diagnosis-btn-kakao:hover,
	.diagnosis-btn-kakao:focus,
	.diagnosis-btn-kakao:active,
	.diagnosis-btn-kakao.focus {
		background-color: #f1d12e !important;
		border-color: #f1d12e !important;
		color: #3b2929 !important;
	}
}

.landing-scroll-to-top {
	position: fixed;
	bottom: 100px;
	right: 20px;

	animation: fadeIn 700ms ease-in-out 1s both;
	cursor: pointer;
	z-index: 1;
}

.begin-section {
	padding: 130px 0;
	position: relative;
}

.begin-application-step-title {
	color: #333333;
	font-size: 24px;
	font-weight: 600;
}

.begin-application-li-indent {
	list-style-position: inside;
	text-indent: -20px;
	padding-left: 20px;
	font-size: 13px;
	font-weight: 400;
}

.review-text {
	color: #333333;
	font-size: 20px;
	font-weight: 400;
}

.review-writer {
	color: #666;
	font-size: 18px;
	font-weight: 400;
}

.review-focus {
	font-weight: 600;
	color: #00418d;
}

.bg-blue {
	background-color: #031944 !important;
}

.begin-blue {
	color: #00418d !important;
}

.begin-blue-light {
	color: #3278f0 !important;
}

.begin-color-text-basic {
	color: #333333 !important;
}

.begin-text-bg-style {
	background-color: #d2e6ff;
	font-weight: 500;
}

.begin-menu {
	color: #333333;
	font-weight: 500;
}

.begin-application-ul-lineheight {
	line-height: 1.8;
}

.begin-application-explain-form {
	width: 100% !important;
	background-color: #f9f9f9;
	padding: 10px;
}

.btn-begin-application {
	width: 100%;
	height: 100%;
	border: 1px;
	border-color: #e1e1e1;
	background: #00418d;
	color: #ffffff;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}
.btn-begin-application:hover,
.btn-begin-application.active,
.btn-begin-application.focus {
	border: 1px;
	border-color: #e1e1e1;
	background: #0a3281;
	color: #ffffff;
}

.btn-begin-application-next {
	width: 157px;
	height: 50px;
	background: #2651a8 !important;
	color: "#ffffff";
	cursor: pointer;
	border-radius: 3px;
}
.btn-begin-application-next:hover,
.btn-begin-application-next:active,
.btn-begin-application-next:focus .btn-begin-application-next.active,
.btn-begin-application-next.focus {
	background: #042e82 !important;
	color: #ffffff;
}

.btn-begin-application-del {
	width: 50px;
	height: 50px;
	border: 1px solid #e1e1e1 !important;
	background: #f7f7f7 !important;
	color: #545454;
	margin-top: "-20px";
	cursor: pointer;
}
.btn-begin-application-del:hover,
.btn-begin-application-del:active,
.btn-begin-application-del:focus .btn-begin-application-next.active,
.btn-begin-application-del.focus {
	background: #c9c9c9 !important;
	color: #333333;
}

.btn-begin-application-add {
	width: 197px;
	height: 50px;
	background: #2e3949 !important;
	border: 1px solid #e5e5e5 !important;
	color: #ffffff;
	cursor: pointer;
	border-radius: 3px;
}
.btn-begin-application-add:hover,
.btn-begin-application-add:active,
.btn-begin-application-add:focus .btn-begin-application-next.active,
.btn-begin-application-add.focus {
	background: #2c3441 !important;
	color: #ffffff;
}

.btn-begin-dropdownitem {
	cursor: pointer;
	display: block;
	width: 100%;
	padding: 0.25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}
.btn-begin-dropdownitem:hover,
.btn-begin-dropdownitem:active,
.btn-begin-dropdownitem:focus {
	background-color: rgb(239, 239, 239);
}

.begin-application-subtitle {
	color: #333333;
	font-size: 18px;
	font-weight: 500;
}

.begin-application-subtitle-1 {
	color: #333333;
	font-size: 16px;
	font-weight: 500;
}

.begin-application-subtitle-2 {
	color: #333333;
	font-size: 16px;
	font-weight: 400;
}

.begin-application-desc {
	color: #333333;
	font-size: 15px;
	font-weight: 400;
}

.begin-application-techdoc-sample-box {
	border-radius: 10px;
	box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.16);
	width: 200px;
	height: 180px;
}

.begin-application-techdoc-cate-title {
	color: #333333;
	font-size: 16px;
	font-weight: 600;
	margin-top: 5px;
}

.begin-application-techdoc-cate-desc {
	color: #666;
	font-size: 14px;
	font-weight: 400;
	margin-top: -10px;
}

.begin-application-techdoc-title-exam {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
}

.begin-application-techdoc-exam-btn1 {
	color: #888888;
	font-size: 12px;
	font-weight: 400;
}

.begin-application-techdoc-exam-btn2 {
	color: #888888;
	font-size: 12px;
	font-weight: 400;
}

.begin-application-agreement {
	color: #333333;
	font-size: 13px;
	font-weight: 400;
	margin-bottom: -5px;
	line-height: 2;
	word-break: keep-all;
}

.begin-application-tip {
	color: #b5b5b5;
	font-size: 12px;
	font-weight: 400;
}

.begin-desc-underline {
	color: #333333;
	font-size: 15px;
	font-weight: 500;
	text-decoration: underline;
}

.begin-underline {
	text-decoration: underline;
}

.font-begin-mypage-table-header {
	color: #666666;
	font-size: 15px;
	font-weight: 400;
}

.font-begin-mypage-table-content {
	color: #333333;
	font-size: 15px;
	font-weight: 400;
}

.begin-auth-ul-margin {
	margin-left: -18px;
}

.begin-auth-btn-size {
	width: 100%;
	height: 80%;
}

.begin-auth-checkbox-size {
	width: 22px;
	height: 22px;
	vertical-align:text-bottom;
}

.begin-application-background {
	/* background: linear-gradient(45deg, #ef4f03, #2f35ff); */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: #fff;
	padding: 20px;
}

.begin-application-col {
	vertical-align: middle !important;
}

.begin-fullsize-button {
	width: "100%";
	height: "100%";
	border: "0px";
	background: "#E5E5E5";
}

.begin-menu:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active {
	color: #48a1ff !important;
}

.btn-begin-light {
	background-color: #f9fafe !important;
	border: 1px solid #666666 !important;
	color: #ffffff !important;
	-webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
	box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-begin-light:hover,
.btn-begin-light:focus,
.btn-begin-light:active,
.btn-begin-light.active,
.btn-begin-light.focus {
	background-color: #e1e1e1 !important;
	border: 1px solid #666666 !important;
	color: #ffffff !important;
}

.btn-begin-light-review {
	background-color: #f5f5f5 !important;
	border: 1px solid #999 !important;
	color: #ffffff !important;
	-webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
	box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-begin-light-review:hover,
.btn-begin-light-review:focus,
.btn-begin-light-review:active,
.btn-begin-light-review.active,
.btn-begin-light-review.focus {
	background-color: #e1e1e1 !important;
	border: 1px solid #999 !important;
	color: #ffffff !important;
}

.btn-begin-blue {
	background-color: #5ba2ff !important;
	border-color: #5ba2ff !important;
	color: #ffffff !important;
	-webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
	box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-begin-blue:hover,
.btn-begin-blue:focus,
.btn-begin-blue:active,
.btn-begin-blue.active,
.btn-begin-blue.focus {
	background-color: #1960b2 !important;
}

.btn-begin-diagnosis {
	background-color: #2651a8 !important;
	color: #ffffff !important;
	font-size: 16px;
	padding: 2% 8%;
	border-radius: 10px;
	-webkit-box-shadow: 0px 3px 5px 0 rgba(248, 249, 252, 0.3);
	box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-begin-diagnosis:hover,
.btn-begin-diagnosis:focus,
.btn-begin-diagnosis:active,
.btn-begin-diagnosis.active,
.btn-begin-diagnosis.focus {
	background-color: #1960b2 !important;
}

.btn-begin-apply:hover,
.btn-begin-applyt:focus,
.btn-begin-applyt:active,
.btn-begin-applyt.active,
.btn-begin-applyt.focus {
	background-color: #042e82 !important;
	border-color: #d4daed !important;
	color: #ffffff !important;
}

.begin-dashboard-title-box {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 161px;
	background-color: #1f293a;
	color: "#fff";
	border-top-right-radius: 40px;
}

.begin-dashboard-menu-box {
	display: flex;
	padding-left: 20px;
	justify-content: start;
	align-items: center;
	width: 150px;
	height: 60px;
	background-color: #1f293a;
	color: "#fff";
}

.begin-dashboard-topmenu-box {
	height: 120px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.begin-dashboard-pa-section-title {
	font-size: 20px;
	font-weight: 500;
	color: #333333;
	margin-top: 5px !important;
}

.begin-dashboard-detail {
	width: 600px;
	padding: 10px;
}

.begin-dashboard-detail-section {
	width: 568x;
	padding: 20px;
	background-color: #f3f3f3;
}

.begin-dashboard-modal-title {
	font-size: 28px;
	font-weight: 400;
	color: #333333;
	margin-top: 25px !important;
}

.begin-dashboard-detail-section-title {
	font-size: 20px;
	font-weight: 500;
	color: #333333;
	margin-top: 25px !important;
}

.begin-dashboard-detail-section-subtitle {
	font-size: 16px;
	font-weight: 500;
	color: #333333;
	margin-bottom: 10px;
}

.begin-dashboard-detail-section-content {
	font-size: 15px;
	font-weight: 400;
	margin-left: 17px;
	color: #00418d;
}

.begin-dashboard-pa-status-title {
	width: 600px;
	padding: 10px;
}

.begin-dashboard-pa-status-list {
	font-size: 16px;
	color: #333333;
}

.begin-dashboard-pa-status-circle-orange {
	width: 130px;
	height: 130px;
	background: white;
	border: 5px solid #f7a300;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.begin-dashboard-pa-status-circle-red {
	width: 130px;
	height: 130px;
	background: white;
	border: 5px solid #da2a21;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.begin-valign-middle {
	display: flex;
	align-items: center;
	justify-content: center;
}

.begin-dashboard-pa-status-color-orange {
	color: #f7a300;
}

.begin-dashboard-pa-status-color-red {
	color: #da2a21;
}

.begin-dashboard-pa-status-dday-orange {
	color: #f7a300;
	font-size: 30px;
	font-weight: 600;
}

.begin-dashboard-pa-status-dday-red {
	color: #da2a21;
	font-size: 30px;
	font-weight: 600;
}

.begin-dashboard-pa-status-color-green {
	color: #1ca29e;
}

.begin-dashboard-pa-status-color-gray {
	color: #777777;
}

.begin-dashboard-list-box {
	border: 1px solid #e6e6e6;
	border-radius: 15px;
	background-color: #fff;
	margin-bottom: 15px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.begin-dashboard-menu {
	color: #f6f6f6 !important;
	font-size: 14px;
	font-weight: 400;
}
.begin-dashboard-menu:hover,
.begin-dashboard-menu:focus,
.begin-dashboard-menu:active,
.begin-dashboard-menu.active,
.begin-dashboard-menu.focus {
	color: #6bb1fb !important;
}

.btn-begin-mypage-menu {
	/* border: 2px solid #e1e1e1 !important; */
	background-color: #fff;
}

.btn-begin-mypage-menu:hover {
	border-bottom: 4px solid #b7d7ff !important;
}
.btn-begin-mypage-menu:focus,
.btn-begin-mypage-menu:active,
.btn-begin-mypage-menu.active,
.btn-begin-mypage-menu.focus {
	border-bottom: 4px solid #1c67b4 !important;
	background-color: #fff;
}

.btn-begin-mypage-download-light {
	background-color: #ffffff !important;
	border: 1px solid #6bb1fb !important;
	color: #308ae9 !important;
	width: 82px;
	height: 27px;
	font-size: 12px;
}

.btn-begin-mypage-download-light:hover,
.btn-begin-mypage-download-light:focus,
.btn-begin-mypage-download-light:active,
.btn-begin-mypage-download-light.active,
.btn-begin-mypage-download-light.focus {
	background-color: #e1e1e1 !important;
}

.btn-begin-mypage-download-dark {
	background-color: #00418d !important;
	border: 0px !important;
	color: #fff !important;
	width: 82px;
	height: 27px;
	font-size: 12px;
}

.btn-begin-mypage-download-dark:hover,
.btn-begin-mypage-download-dark:focus,
.btn-begin-mypage-download-dark:active,
.btn-begin-mypage-download-dark.active,
.btn-begin-mypage-download-dark.focus {
	background-color: #002857 !important;
}

.btn-begin-mypage-download-navy-outline {
	border: 1px solid #2064b4 !important;
	background-color: #ffffff !important;
	color: #00418d !important;

	height: 27px;
	font-size: 12px;
}

.btn-begin-mypage-download-navy-outline:hover,
.btn-begin-mypage-download-navy-outline:focus,
.btn-begin-mypage-download-navy-outline:active,
.btn-begin-mypage-download-navy-outline.active,
.btn-begin-mypage-download-navy-outline.focus {
	background-color: #00418d !important;
	color: #ffffff !important;
}

.btn-begin-mypage-download-gray-outline {
	border: 1px solid #2e3949 !important;
	background-color: #2e3949 !important;
	color: #ffffff !important;

	height: 27px;
	font-size: 12px;
}

.btn-begin-mypage-download-gray-outline:hover,
.btn-begin-mypage-download-gray-outline:focus,
.btn-begin-mypage-download-gray-outline:active,
.btn-begin-mypage-download-gray-outline.active,
.btn-begin-mypage-download-gray-outline.focus {
	background-color: #ffffff !important;
	color: #2e3949 !important;
}

.btn-begin-mypage-download-gray {
	background-color: #808080 !important;
	border: 0px !important;
	color: #fff !important;
	width: 82px;
	height: 27px;
	font-size: 12px;
}

.btn-begin-mypage-download-gray:hover,
.btn-begin-mypage-download-gray:focus,
.btn-begin-mypage-download-gray:active,
.btn-begin-mypage-download-gray.active,
.btn-begin-mypage-download-gray.focus {
	background-color: #555555 !important;
}

.btn-begin-mypage-report-selected {
	border: 1px solid #002857 !important;
	background-color: #002857 !important;
	color: white !important;
	font-size: 12px;
	padding: 5px 10px;
}

.btn-begin-mypage-report-unselected {
	border: 1px solid #2064b4 !important;
	background-color: white !important;
	color: #00418d !important;
	font-size: 12px;
	padding: 5px 10px;
}

.component {
	height: 100%;
	width: 100%;
	display: flex;
}
.component.initial-step-component {
	background-color: gray;
	position: relative;
}

.begin-about-btn-regist {
	font-size: 14px;
	border-radius: 4px;
	padding: 8px 12px;
	border: 1px solid #3b7ac4;
	background-color: #f5f5f5;
	color: #00418d;
	font-weight: 500;
}
.begin-about-btn-regist:hover {
	background-color: #e0f1fb;
}

.begin-fee-btn-invoice {
	font-size: 16px;
	border-radius: 4px;
	padding: 14px 12px;
	border: 1px solid #707070;
	background-color: #fff;
	color: #666;
	width: 60%;
	font-weight: 500;
}
.begin-fee-btn-invoice:hover {
	border: 1px solid #00418d;
	background-color: #e0f1fb;
	color: #00418d;
}

.begin-about-btn-regist-report {
	font-size: 14px;
	border-radius: 4px;
	padding: 8px 12px;
	background-color: #fddc3f !important;
	border: 1px solid #fddc3f !important;
	color: #3b2929 !important;
	font-weight: 500;
}
.begin-about-btn-regist-report:hover {
	background-color: #f1d12e !important;
	border-color: #f1d12e !important;
	color: #3b2929 !important;
}

.application-contain {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	flex-direction: column;
}

.begin-about-sub-title {
	font-size: 20px;
	color: #333333;
	font-weight: 400;
}

.btn-group button {
	background-color: white; /* Green background */
	border: 1px solid #579beb; /* Green border */
	border-radius: 3px;
	font-size: 12px;
	color: #136ed9; /* White text */
	padding: 7px 12px; /* Some padding */
	cursor: pointer; /* Pointer/hand icon */
	float: left; /* Float the buttons side by side */
}

.btn-group button:not(:last-child) {
	border-right: none; /* Prevent double borders */
}

/* Clear floats (clearfix hack) */
.btn-group:after {
	content: "";
	clear: both;
	display: table;
}

/* Add a background color on hover */
/* .btn-group button:hover, */
.btn-group button:focus,
.btn-group button.active,
.btn-group button.focus {
	background-color: #579beb;
	color: white; /* White text */
}

.detail-link {
	color: #00418d;
	cursor: pointer;
	text-decoration: underline;
	font-size: 18px;
}

.begin-diagnosis-question {
	font-size: 18px;
	font-weight: 500;
	color: #333;
	word-break: keep-all;
}

.begin-diagnosis-answer {
	font-size: 15px;
	font-weight: 400;
	color: #333;
	word-break: keep-all;
}

.begin-diagnosis-process-title-in-desc {
	font-size: 18px;
	font-weight: 600;
	margin-left: -20px;
	color: #00418d;
}

.begin-diagnosis-warn {
	font-size: 14px;
	color: #da2a21;

	line-height: 80%;
}

.begin-diagnosis-card-radius {
	border-radius: 15px;
}

.begin-diagnosis-result-title {
	font-size: 28px;
	font-weight: 600;
	color: #00418d;
}

.btn-diagnosis_btn_blue {
	background-color: #00418d !important;
	border: 1px solid #00418d !important;
	color: white !important;
	border-radius: 7px !important;
	/* -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3); */
	font-size: 14px;
	font-weight: 500;
	width: 160px;
	margin-top: 10px;
	box-shadow: none;

	/* box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3); */
}

.btn-diagnosis_btn_blue:hover,
.btn-diagnosis_btn_blue:focus,
.btn-diagnosis_btn_blue:active,
.btn-diagnosis_btn_blue.active,
.btn-diagnosis_btn_blue.focus {
	background-color: #002249 !important;
	border: 1px solid #002249 !important;
	color: white !important;
}

.btn-diagnosis_btn_blue_line {
	background-color: #f1f6ff !important;
	border: 1px solid #3b7ac4 !important;
	color: #024088 !important;
	border-radius: 7px !important;
	/* -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3); */
	font-size: 14px;
	font-weight: 500;
	width: 160px;
	margin-top: 10px;
	box-shadow: none;

	/* box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3); */
}

.btn-diagnosis_btn_blue_line:hover,
.btn-diagnosis_btn_blue_line:focus,
.btn-diagnosis_btn_blue_line:active,
.btn-diagnosis_btn_blue_line.active,
.btn-diagnosis_btn_blue_line.focus {
	background-color: #b7c6db !important;
	border: 1px solid #3b7ac4 !important;
	color: #024088 !important;
}

@media (min-width: 769px) {
	.service-font-h1 {
		font-size: 34px;
		font-weight: 700;
		color: 333;
		word-break: keep-all;
	}

	.service-font-h2 {
		font-size: 24px;
		font-weight: 400;
		color: 333;
	}

	.service-font-h3 {
		font-size: 20px;
		font-weight: 400;
		color: 333;
		word-break: keep-all;
	}

	.service-font-h4 {
		font-size: 18px;
		font-weight: 400;
		color: 333;
	}

	.service-font-h5 {
		font-size: 16px;
		font-weight: 400;
		color: 333;
	}

	.img-width {
	}
}

@media (max-width: 768px) {
	.service-font-h1 {
		font-size: 28px;
		font-weight: 700;
		color: 333;
		word-break: keep-all;
	}

	.service-font-h2 {
		font-size: 20px;
		font-weight: 400;
		color: 333;
	}

	.service-font-h3 {
		font-size: 18px;
		font-weight: 400;
		color: 333;
		word-break: keep-all;
	}

	.service-font-h4 {
		font-size: 16px;
		font-weight: 400;
		color: 333;
	}

	.service-font-h5 {
		font-size: 14px;
		font-weight: 400;
		color: 333;
	}

	.img-width {
		width: 100%;
	}
}

.active-menu {
	font-size: 25px;
	font-weight: 600;
	color: 333;
}

.deactive-menu {
	font-size: 20px;
	font-weight: 400;
	color: 666;
}

.main-table-td-short {
	width: 32px;
	text-align: center;
	border: 1px;
}

.main-table-td {
	border: none;
}

::placeholder {
	color: #b5b5b5 !important;
	opacity: 1; /* Firefox */
}


p{
word-break: keep-all;
}

